<template>
  <section>
    <!-- <div class="bTitle">
      <span>Документация</span>
    </div> -->

    <v-container>
      <h3 class="pb-6">Документация</h3>
      <p>
        <a
          href="/Описание_функциональных_характеристик_программного_обеспечения.pdf"
          target="_blank"
          >Описание функциональных характеристик программного обеспечения</a
        >
      </p>
      <p>
        <a href="/Руководство_по_установке_и_эксплуатации.pdf" target="_blank"
          >Руководство по установке и эксплуатации</a
        >
      </p>
      <p>
        <a href="/Руководство_бекофиса.pdf" target="_blank"
          >Руководство бекофиса</a
        >
      </p>
      <p>
        <a href="/Руководство_пользователя.pdf" target="_blank"
          >Руководство пользователя</a
        >
      </p>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "DocumentsComponent",
  components: {},
  data() {
    return {}
  },
  methods: {
    showModal() {},
    showModalResult() {},
  },
  mounted() {},
  destroyed() {},
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 80px 0;
  @media (max-width: $xl) {
    padding: 40px 0;
  }
}
</style>

<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Как это работает</span>
      </div>

      <div class="bBoxes" data-aos="fade-up">
        <div class="bBoxes__item v1">Потенциальные приобретатели продукта</div>

        <div class="bBoxes__item v2">Микро-агенты</div>

        <div class="bBoxes__item v3">
          <img class="header__logo" src="@/static/images/Vector111.svg" />
        </div>

        <div class="bBoxes__item v4">
          Компания <br />
          (клиент, продавец продукта)
        </div>

        <div class="bBoxes__line v1-sm"></div>
        <div class="bBoxes__line v2-sm"></div>
        <div class="bBoxes__line v3-sm"></div>
        <div class="bBoxes__line v4-sm"></div>

        <div class="bBoxes__line v1"></div>
        <div class="bBoxes__line v2"></div>
        <div class="bBoxes__line v3"></div>
        <div class="bBoxes__line v4"></div>

        <div class="bBoxes__text v1">Сделка</div>
        <div class="bBoxes__text v2">
          Автоматизация, документооборот, администрирование платежей,
          информационный обмен
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "SchemeComponent",
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 0 0 120px;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}

.bBoxes {
  position: relative;
  min-height: 630px;
  @media (max-width: $xl) {
    min-height: 530px;
  }

  $w: 1640;
  $arr: 6px;

  &__text {
    position: absolute;
    color: var(--Grey-1, #4e5d78);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    font-size: 18px;
    @media (max-width: $xl) {
      font-size: 14px;
    }

    &.v1 {
      @media (max-width: $xl) {
        padding: 0 0 80px 0;
      }

      top: 114px;
      left: percentage(1277 / $w);
      @media (max-width: $xl) {
        top: 10px;
        left: 265px;
      }
    }

    &.v2 {
      text-align: center;
      background-color: #fff;

      top: 462px;
      left: percentage(652 / $w);
      width: 335px;
      @media (max-width: $xl) {
        top: 362px;
        left: 0;
        width: 260px;
      }
    }
  }

  &__item {
    position: absolute;
    display: inline-flex;
    padding: 15px 13px;
    justify-content: center;
    align-items: center;
    // text-align: center;

    border-radius: 12px;
    border: 1px solid var(--Greyscale-100, #dfe1e6);
    background: var(--Greyscale-25, #f8f9fb);
    box-shadow: 0px 1px 3px 0px rgba(13, 13, 18, 0.05),
      0px 1px 2px 0px rgba(13, 13, 18, 0.04);

    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    min-height: 92px;
    width: 335px;
    font-size: 22px;
    @media (max-width: $xl) {
      font-size: 14px;
      min-height: 72px;
      width: 260px;
    }

    &.v1 {
      top: 95px;
      left: percentage(138 / $w);
      @media (max-width: $xl) {
        top: 0;
        left: 0;
      }
    }

    &.v2 {
      top: 342px;
      left: percentage(138 / $w);
      @media (max-width: $xl) {
        top: 120px;
        left: 0;
      }
    }

    &.v3 {
      top: 342px;
      left: percentage(652 / $w);
      @media (max-width: $xl) {
        top: 240px;
        left: 0;
      }
    }

    &.v4 {
      top: 342px;
      left: percentage(1166 / $w);
      @media (max-width: $xl) {
        top: 460px;
        left: 0;
      }
    }
  }

  &__line {
    position: absolute;

    &.v1-sm {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }

      border-left: 2px dashed #89969f;

      top: 72px;
      left: 120px;
      height: 120 - 72px;

      &::before {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-bottom: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        top: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-top: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        bottom: -1px;
      }
    }
    &.v2-sm {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }

      border-left: 2px dashed #89969f;

      top: 72px + 120;
      left: 120px;
      height: 120 - 72px;

      &::before {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-bottom: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        top: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-top: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        bottom: -1px;
      }
    }
    &.v3-sm {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }

      border-left: 2px dashed #89969f;

      top: 72px + 120 * 2;
      left: 120px;
      height: 220 - 72px;

      &::before {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-bottom: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        top: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-top: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        bottom: -1px;
      }
    }

    &.v4-sm {
      display: none;
      @media (max-width: $lg) {
        display: block;
      }

      top: 36px;
      left: 260px;
      height: 460px;
      width: 40px;

      border-top: 2px dashed #89969f;
      border-right: 2px dashed #89969f;
      border-bottom: 2px dashed #89969f;
      border-radius: 0 10px 10px 0;

      &::before {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-right: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 - 1;
        left: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-right: $arr solid #89969f;
        position: absolute;
        left: -1px;
        bottom: $arr * -1 - 1;
      }
    }

    &.v1 {
      display: block;
      @media (max-width: $lg) {
        display: none;
      }

      border-left: 2px dashed #89969f;
      top: 187px;
      left: calc(#{percentage(138 / $w)} + 136px);
      height: 342 - 187px;

      &::before {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-bottom: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        top: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-top: $arr solid #89969f;
        position: absolute;
        left: $arr * -1 - 1;
        bottom: -1px;
      }
    }
    &.v2 {
      display: block;
      @media (max-width: $lg) {
        display: none;
      }

      top: 385px;
      left: calc(#{percentage(138 / $w)} + 335px);
      right: 100 - percentage(652 / $w);
      border-bottom: 2px dashed #89969f;

      &::before {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-right: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 + 1;
        left: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-left: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 + 1;
        right: -1px;
      }
    }

    &.v3 {
      display: block;
      @media (max-width: $lg) {
        display: none;
      }

      top: 385px;
      left: calc(#{percentage(652 / $w)} + 335px);
      right: 100 - percentage(1166 / $w);
      border-bottom: 2px dashed #89969f;

      &::before {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-right: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 + 1;
        left: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-left: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 + 1;
        right: -1px;
      }
    }

    &.v4 {
      display: block;
      @media (max-width: $lg) {
        display: none;
      }

      top: 146px;
      height: 194px;
      left: calc(#{percentage(138 / $w)} + 335px);
      right: calc(#{100 - percentage(1166 / $w)} - 170px);

      border-top: 2px dashed #89969f;
      border-right: 2px dashed #89969f;
      border-radius: 0 10px 0 0;

      &::before {
        display: block;
        content: "";
        border-top: $arr solid transparent;
        border-bottom: $arr solid transparent;
        border-right: $arr solid #89969f;
        position: absolute;
        top: $arr * -1 - 1;
        left: -1px;
      }
      &::after {
        display: block;
        content: "";
        border-left: $arr solid transparent;
        border-right: $arr solid transparent;
        border-top: $arr solid #89969f;
        position: absolute;
        right: $arr * -1 - 1;
        bottom: -1px;
      }
    }
  }
}

/* img {
  max-width: 100%;
  height: auto;
} */
</style>

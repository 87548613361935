import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

const v = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1F2937",
        //
        background: "#fff",
        contrast: "#f5f5f5",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
})

export default v

<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="v-input-custom rounded-lg"
  />
</template>

<script>
export default {
  name: 'VInputCustom'
}
</script>

<style lang="scss" scoped>
.v-input-custom {
  ::v-deep {
    &.v-input {
      color: #000000 !important;

      .v-label {
        color: #000000 !important;
      }

      &.error--text {
        color: #FF0011 !important;

        .v-label {
          color: #FF0011 !important;
        }
      }
    }
  }
}
</style>
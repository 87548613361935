<template>
  <main class="main">
    <BannerDefault class="banner" id="about" />
    <StatsComponent class="stats" id="" />
    <AgentsComponent class="agents" id="who" />
    <BenefitsDefault class="benefits" id="opportunities" />
    <SchemeDefault class="schemes" id="" />
    <QuestionsComponent class="questions" id="faq" />
    <PriceComponent class="prices" id="price" />
    <ContactComponent class="contacts" id="" />
  </main>
</template>

<script>
import {
  BannerDefault,
  AgentsComponent,
  StatsComponent,
  BenefitsDefault,
  SchemeDefault,
  QuestionsComponent,
  PriceComponent,
  ContactComponent,
} from "@/components/blocks"

export default {
  name: "MainDefault",
  components: {
    BannerDefault,
    AgentsComponent,
    StatsComponent,
    BenefitsDefault,
    SchemeDefault,
    QuestionsComponent,
    PriceComponent,
    ContactComponent,
  },
}
</script>

<style lang="scss" scoped></style>

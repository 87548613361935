<template>
  <section>
    <v-container>
      <div class="banner">
        <div class="banner__promotion" data-aos="fade-up">
          Рост продаж на&nbsp;30-100% за&nbsp;счет запуска нового канала
          <!-- <img src="@/static/images/chevron-right.svg" alt="" /> -->
        </div>

        <div class="banner__content">
          <h1 data-aos="fade-up">
            Платформа автоматизации продаж <br />в&nbsp;микроагентском канале
            <br />для вашего бизнеса
          </h1>

          <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2">
              <p data-aos="fade-up">
                Мы&nbsp;помогаем компаниям в&nbsp;финансовой, строительной
                и&nbsp;розничной отраслях запустить эффективные продажи через
                микроагентов
              </p>
            </div>
          </div>

          <div class="text-center" data-aos="fade-up">
            <v-btn
              color="primary"
              elevation="0"
              class="mb-2 mr-5"
              @click="$root.$emit('show-modal')"
              >Попробовать</v-btn
            >
            <v-btn
              color="secondary"
              outlined
              elevation="0"
              class="mb-2"
              @click="$root.$emit('show-modal')"
              >Узнать преимущества</v-btn
            >
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "BannerDefault",
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 120px 0;
  @media (max-width: $xl) {
    padding: 50px 0 60px 0;
  }
}

.banner {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  &__promotion {
    display: inline-flex;
    align-items: center;

    // border: 1px solid #dfe1e6;
    // border-radius: 8px;
    padding: 4px 10px;
    // box-shadow: 0 1px 2px 0 rgba(13, 13, 18, 0.06);
    // background: #fff;
    margin-bottom: 30px;

    color: #0d0d12;

    font-size: 16px;
    line-height: 160%;
    @media (max-width: $xl) {
      font-size: 14px;
      line-height: 120%;
    }

    img {
      width: 13px;
      height: 13px;
      margin-left: 4px;
    }
  }

  &__content {
    h1 {
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.01em;
      text-align: center;
      color: #0d0d12;
      margin-bottom: 20px;

      font-size: 64px;
      @media (max-width: $xl) {
        font-size: 22px;
      }
    }

    p {
      line-height: 150%;
      text-align: center;
      color: #666d80;

      margin-bottom: 30px;

      font-size: 20px;
      @media (max-width: $xl) {
        font-size: 14px;
      }
    }
  }

  .btn {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #1f2937;

    &.btn-black {
      border-color: transparent;
      background-color: #1f2937;
      color: #fff;
    }

    &.btn-secondary {
    }
  }
}
</style>

<template>
  <div class="modal contrast rounded-xxl">
    <v-icon @click="closeHandler" class="modal__close secondary--text">
      mdi-close-circle-outline
    </v-icon>
    <div v-if="!success" class="modal__form">
      <div class="modal__title secondary--text mb-4">
        Заполните информацию о себе и зарабатывайте со Спортмастером
      </div>
      <v-form ref="form" v-model="valid">
        <v-input-custom v-model="form.company" label="Название компании" />

        <v-input-custom
          v-model="form.email"
          label="Ваша электронная почта *"
          required
          :rules="rules.email"
        />

        <v-input-custom
          v-model="form.fio"
          label="ФИО *"
          required
          :rules="rules.fio"
        />

        <v-input-custom v-model="form.position" label="Должность" />

        <v-input-custom v-model="form.comment" label="Комментарий " />

        <div class="d-flex pb-6">
          <v-checkbox
            v-model="policyAgree"
            color="primary"
            value="primary"
            hide-details
            required
            :rules="rules.policyAgree"
            dense
            class="form__checkbox mr-sm-2"
          />
          <div class="form__checkbox-label">
            Я даю согласие на обработку моих персональных данных, а также
            ознакомлен
            <a
              href="/политика_обработки_персональных_данных.pdf"
              target="_blank"
              >с условиями и политикой в отношении их обработки.</a
            >
          </div>
        </div>
      </v-form>
      <v-btn color="primary" elevation="0" block @click="sendForm">
        Присоединиться
      </v-btn>
    </div>

    <div v-if="success" class="modal__notify d-flex flex-column align-center">
      <img
        src="@/assets/svg/success.svg"
        alt="success icon"
        class="modal__notify-icon"
      />
      <div class="modal__notify-success secondary--text">
        <div class="text-center">Успешно!</div>
        <br />
        <div class="text-center">
          Мы уже почти все приготовили для вашей работы!
        </div>
        <br />
        <div class="text-center">
          В ближайшее время программа будет запущена, и мы сразу сообщим вам о
          возможности начать зарабатывать со Спортмастер!
        </div>
      </div>
      <v-btn color="primary" elevation="0" @click="closeHandler">
        Понятно
      </v-btn>
    </div>
  </div>
</template>

<script>
import { VInputCustom, VSelectCustom } from "@/components/ui"

const FORM = {
  company: "",
  email: "",
  fio: "",
  position: "",
  comment: "",
}

export default {
  name: "ModalDefault",
  components: { VInputCustom, VSelectCustom },
  data() {
    return {
      valid: false,
      success: false,
      policyAgree: false,

      form: {
        ...FORM,
      },
      rules: {
        policyAgree: [(v) => !!v || "Обязательно"],
        fio: [(v) => !!v || 'Поле "ФИО" обязательно'],
        email: [
          (v) => !!v || 'Поле "Email" обязательно',
          (v) =>
            /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(v) ||
            'Поле "Email" не верное',
        ],
      },
      isError: false,
    }
  },
  watch: {},
  methods: {
    async sendForm() {
      const isValid = await this.$refs.form?.validate()
      if (!isValid) return alert("Заполните обязательные поля")

      // const url = "http://localhost:4004"
      const url = "https://api.xn--80ahlldqgjs.xn--p1ai"
      fetch(`${url}/hook/saveLead`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...this.form,
        }),
      })
        .then(() => {
          this.isError = false
          this.$root.$emit("show-modal-result")
        })
        .catch(() => {
          this.isError = true
        })
        .finally(() => {
          !this.isError && (this.form = { ...FORM })
          this.$refs.form?.resetValidation?.()
          this.dialog = true
        })
    },

    closeHandler() {
      this.success = false
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__checkbox {
    &.error--text + .form__checkbox-label {
      color: #ff0011 !important;
    }

    ::v-deep {
      .v-input__slot {
        align-items: start;
      }

      .v-icon {
        // color: black;
        // font-size: 28px;

        // @media (min-width: map-get($grid-breakpoints, "sm")) {
        // font-size: 36px;
        // }
      }
    }

    &-label {
      // @media (min-width: map-get($grid-breakpoints, "sm")) {
      // font-size: 20px;
      // }
    }
  }
}

.modal {
  padding: 40px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
  }

  .modal__close {
    opacity: 0.3;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .modal__title {
    font-size: 18px;
    width: 95%;
  }

  .modal__notify-icon {
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .modal__notify-button {
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
}
</style>

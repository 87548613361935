<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Вопрос - Ответ</span>
      </div>

      <div class="question">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h1 data-aos="fade-up">
              Проблемы бизнеса и их решения от Лидпортал
            </h1>
          </div>
          <div class="col-12 col-lg-6">
            <v-expansion-panels data-aos="fade-up">
              <v-expansion-panel v-for="(item, index) in items" :key="index">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="header-content">
                      <span>{{ item.question }}</span>
                      <v-icon>{{ open ? "mdi-minus" : "mdi-plus" }}</v-icon>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ item.answer }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "QuestionsComponent",
  data() {
    return {
      items: [
        {
          question: "Как привлечь микроагентов?",
          answer:
            "Мы подскажем, как построить работу по поиску и привлечению агентов и дадим автоматизированные инструменты",
        },
        {
          question:
            "Привлечение тысяч микро-агентов - это дорого.. Мы пробовали: выходит от 15 тыс руб на одного агента",
          answer:
            "Наш высокоавтоматизированный процесс делает привлечение кратно дешевле, а конверсию в работающего агента в 2-3 раза выше. Регистрация агента занимает несколько минут. Интерактивное обучение и удобный интерфейс быстро вводят его в курс дела.",
        },
        {
          question: "Как бороться с фродом?",
          answer: "Мы делаем верификацию агентов. Процесс автоматизирован.",
        },
        {
          question:
            "Как агенты подают заявки на продукты и получают обратную связь?",
          answer:
            "Лидпортал интегрируется с вашими системами по API или любым другим способом и обеспечивает передачу информации всем участникам. У нас также есть удобные Личные кабинеты для всех ролей участников процесса.",
        },
        {
          question: "У нас низкая конверсия заявок от агентов в продажи",
          answer:
            "Наша платформа увеличивает конверсию в 1,5-3 раза за счет удобного автоматизированного процесса, интеграции с продакшн-системами клиента и настроенных коммуникаций",
        },
        {
          question:
            "Как платить агентам? Ведь это много мелких платежей. Долго им ждать выплат?",
          answer:
            "Мы берем это на себя. Агенты получают выплаты на свой банковский счет в рекордно быстрые сроки: иногда уже на следующий день после сделки. И, да, им это очень нравится! Закрывающие документы и чека для ЮЛ, ИП и самозанятых мы тоже обработаем сами.",
        },
        {
          question:
            "А как платить агентам - физлицам? Ведь за них надо платить налоги и отчитываться в Соцфонд РФ..",
          answer:
            "Да, мы работаем с агентами - физлицами. Лидпортал берет расчеты на себя. Процесс автоматизирован, эффективен и полностью соответствует законодательству РФ. Физлица получают на руки вознаграждение после уплаченных налогов - ничего больше делать не нужно.",
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 0 0 120px;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}

h1 {
  color: #0d0d12;
  line-height: 120%;

  font-size: 36px;
  font-weight: 600;
  @media (max-width: $xl) {
    font-size: 18px;
    font-weight: 500;
  }
}

.v-expansion-panels {
  // justify-content: flex-end;
  gap: 16px;
  // width: 820px;
}

.v-expansion-panel {
  border: 1px solid #dfe1e6;
  border-radius: 12px;

  &::before,
  &::after {
    display: none;
  }
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border-radius: 12px;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}

.v-expansion-panel-header {
  padding: 20px 20px;
  border-radius: 12px;
  box-shadow: none;
  border: none;

  font-weight: 600;
  color: #0d0d12;
  min-height: auto;

  font-size: 20px;
  line-height: 150%;
  @media (max-width: $xl) {
    font-size: 16px;
    line-height: 120%;
  }
}

.v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;

  font-size: 18px;
  line-height: 150%;
  color: #666d80;
}
.v-expansion-panel-header__icon {
  display: none !important;
}
</style>

<template>
  <main class="main">
    <DocumentsComponent />
  </main>
</template>

<script>
import { DocumentsComponent } from "@/components/blocks"

export default {
  name: "MainDefault",
  components: {
    DocumentsComponent,
  },
}
</script>

<style lang="scss" scoped></style>

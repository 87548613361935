<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Микро-агенты</span>
      </div>
      <div class="agent">
        <div class="row aling-center">
          <div class="col-12 col-lg-6 agent__img">
            <img src="@/static/images/bg-micro-agents.png" data-aos="fade-up" />
          </div>
          <div class="col-12 col-lg-6 d-flex align-center agent__text">
            <div data-aos="fade-up">
              Это независимые профессионалы, которые могут<br />
              продавать ваш продукт в связке со своей<br />
              основной деятельностью<br />
              <br />
              Например, частный риелтор может продать<br />
              ипотеку банка своему клиенту. На рынке - сотни<br />
              тысяч потенциальных<br />
              микро-агентов в разных отраслях
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "AgentsComponent",
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 0 0 100px 0;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }

  .agent {
    max-width: 1368px;
    margin: 0 auto;

    &__img {
      text-align: right;
      @media (max-width: $xl) {
        text-align: center;
      }

      img {
        max-width: 100%;
        border-radius: 24px;
      }
    }

    &__text {
      font-size: 24px;
      line-height: 133%;

      font-weight: bold;
      position: relative;

      font-weight: bold;
      padding-left: 45px;
      font-size: 24px;
      line-height: 133%;
      @media (max-width: $xl) {
        font-weight: normal;
        padding-left: 0px;
        font-size: 14px;
        line-height: 120%;
      }
      @media (max-width: $sm) {
        padding-left: 12px;
      }
    }
  }
}
</style>
